<template>
  <div class="container mt-4">
    <b-row class="my-3">
      <b-col class="d-flex align-items-center justify-content-between">
        <h5>{{ translations.create_and_details.page_title_details }}</h5>
        <b-button
          data-testid="btn-save"
          variant="primary"
          :disabled="saving || cannotSave"
          @click="save">
          {{ translations.create_and_details.btn_save }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-form-group
          id="input-name"
          :invalid-feedback="handleError('rvpForm.name')"
          :state="!validateRequiredFields || isValid('rvpForm.name')"
          :label="translations.create_and_details.form.name"
          class="flex-1">
          <b-form-input
            id="input-name"
            :disabled="!rvpForm.is_active"
            v-model.trim="rvpForm.name"
            maxlength="254"/>
          </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-salesforce-id"
          :invalid-feedback="handleError('rvpForm.salesforce_id')"
          :state="!validateRequiredFields || isValid('rvpForm.salesforce_id')"
          :label="translations.create_and_details.form.salesforce_id"
          class="flex-1">
          <b-form-input
            id="input-salesforce-id"
            :disabled="!rvpForm.is_active"
            v-model.trim="rvpForm.salesforce_id"
            maxlength="254"/>
          </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-title"
          :invalid-feedback="handleError('rvpForm.title')"
          :state="!validateRequiredFields || isValid('rvpForm.title')"
          :label="translations.create_and_details.form.title"
          class="flex-1">
          <b-form-input
            id="input-title"
            :disabled="!rvpForm.is_active"
            v-model.trim="rvpForm.title"/>
          </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-email"
          :invalid-feedback="handleError('rvpForm.email')"
          :state="!validateRequiredFields || isValid('rvpForm.email')"
          :label="translations.create_and_details.form.email"
          class="flex-1">
          <b-form-input
            id="input-email"
            :disabled="!rvpForm.is_active"
            v-model.trim="rvpForm.email"/>
          </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-form-group
          class="mx-1"
          :label="translations.create_and_details.form.is_active">
          <toggle-button
            :disabled="cannotSave"
            :value="rvpForm.is_active"
            @change="onChangeToggle('is_active', ...arguments)"
            sync/>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="mt-3">
      <list-and-add-RVPs-plan-configs
        :rvp-uuid="rvpUuid"
        :currentConfigs="rvpDetails.rvp_plans"/>
    </div>
  </div>
</template>

<script>
import { parseResponseError } from '@/http/parsers/parse_response';
import { required, email } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import service from '@/services/commission';
import translations from '@/translations';
import ListAndAddRVPsPlanConfigs from '@/components/Commissions/RVPs/PlanConfigurations/listAndAdd.vue';

export default {
  name: 'RVPEditAndDetails',
  mixins: [ errorHandler ],
  components: {
    ListAndAddRVPsPlanConfigs,
  },
  data() {
    return {
      translations: translations.commissions.rvps,
      saving: false,
      cannotSave: false,
      rvpUuid: '',
      rvpDetails: { rvp_plans: [] },
      rvpForm: {
        name: null,
        salesforce_id: null,
        title: null,
        email: null,
        is_active: false,
      },
      validateRequiredFields: false,
    };
  },
  validations() {
    return this.rules;
  },
  async beforeMount() {
    this.rvpUuid = this.$route?.params?.id || '';
    await this.fetchRVP();
  },
  computed: {
    rules() {
      return {
        rvpForm: {
          name: { required },
          salesforce_id: { required },
          title: { required },
          email: { required, email },
        },
      };
    },
  },
  methods: {
    async fetchRVP() {
      try {
        this.rvpDetails = await service.getRVP(this.rvpUuid);
        this.fillDetailsForm(this.rvpDetails);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_details}: ${parseResponseError(err)}`);
      }
    },
    async save() {
      this.validateRequiredFields = true;
      if (this.$v.rvpForm.$invalid) {
        return;
      }

      if (!this.rvpForm.is_active && !await this.$bvModal.msgBoxConfirm(this.translations.create_and_details.confirm_save, {
        okTitle: this.translations.create_and_details.btn_save,
      })) {
        this.onChangeToggle('is_active', { value: true });
        return;
      }

      try {
        this.saving = true;
        this.rvpDetails = await service.updateRVP(this.rvpUuid, this.rvpForm);
        this.$noty.success(this.translations.success.update);
        this.fillDetailsForm(this.rvpDetails);
        // avoid saving form when the rvp is inactive
        this.onChangeToggle('is_active', { value: this.rvpDetails.is_active });
      } catch (err) {
        let errMessage = err?.response?.data?.error || this.translations.errors.save;
        if (errMessage?.includes('there is already a RVP with this salesforce ID')) {
          errMessage = this.translations.errors.already_exists;
        }
        this.$noty.error(errMessage);
      } finally {
        this.saving = false;
      }
    },
    onChangeToggle(toggle, value) {
      this.rvpForm[toggle] = value?.value;
    },
    fillDetailsForm(newDetailsData) {
      this.rvpForm = {
        name: newDetailsData.name,
        salesforce_id: newDetailsData.salesforce_id,
        title: newDetailsData.title,
        email: newDetailsData.email,
        is_active: newDetailsData.is_active,
      };

      // avoid saving form when the rvp is inactive
      this.cannotSave = !this.rvpForm.is_active;
    },
  },
};
</script>
