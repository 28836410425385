<template>
  <section>
    <p class="font-weight-bold">{{ translations.plan_configs.section_title }}</p>
    <table class="table table-stripped">
      <thead>
        <tr>
          <th>{{ translations.plan_configs.table.plan }}</th>
          <th>{{ translations.plan_configs.table.key }}</th>
          <th>{{ translations.plan_configs.table.value }}</th>
          <th>{{ translations.plan_configs.table.start_date }}</th>
          <th>{{ translations.plan_configs.table.end_date }}</th>
          <th>{{ translations.plan_configs.table.status }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="configsFormated.length === 0">
          <td colspan="6" class="text-center">{{ translations.plan_configs.table.no_data }}</td>
        </tr>
        <tr v-for="(config, index) in configsFormated" :key="index">
          <td style="min-width: 8rem;">{{ getPlanNameById(config.plan_id) }}</td>
          <td style="min-width: 16rem;">{{ configsKeys[config.key] }}</td>
          <td style="min-width: 8rem;">{{ config.value }}</td>
          <td style="min-width: 16rem;">{{ formatDate(config.start_date) }}</td>
          <td style="min-width: 16rem;">{{ formatDate(config.end_date) }}</td>
          <td>
            <b-badge
              class="text-capitalize"
              :variant="statusBadgeVariant[config.status]">
              {{ config.status }}
            </b-badge>
          </td>
        </tr>
        <tr v-show="!!rvpUuid">
          <td style="min-width: 8rem;">
            <b-form-group
            class="w-100 p-0"
            :invalid-feedback="handleError('rvpPlanConfigForm.plan_id')"
            :state="isValidState('rvpPlanConfigForm.plan_id')">
              <b-select
              v-model="rvpPlanConfigForm.plan_id"
              :options="planOptions"
              @change="handleChangePlanId"/>
            </b-form-group>
          </td>
          <td style="min-width: 16rem;">
            <b-form-group
            class="w-100 p-0"
            :invalid-feedback="handleError('rvpPlanConfigForm.key')"
            :state="isValidState('rvpPlanConfigForm.key')">
              <b-select
              v-model="rvpPlanConfigForm.key"
              :disabled="!rvpPlanConfigForm.plan_id"
              :options="keysOptions"/>
            </b-form-group>
          </td>
          <td style="min-width: 8rem;">
            <b-form-group
            class="w-100 p-0"
            :invalid-feedback="handleError('rvpPlanConfigForm.value')"
            :state="isValidState('rvpPlanConfigForm.value')">
              <b-form-input
                trim
                v-model="rvpPlanConfigForm.value"
                :formatter="formatInputValue"/>
            </b-form-group>
          </td>
          <td style="min-width: 16rem;">
            <b-form-group
            class="w-100 p-0"
            :invalid-feedback="handleError('rvpPlanConfigForm.start_date')"
            :state="isValidState('rvpPlanConfigForm.start_date')">
              <vue-ctk-date-time-picker
              auto-close
              only-date
              no-label
              label=""
              format="YYYY-MM-DD"
              formatted="ll"
              v-model="rvpPlanConfigForm.start_date"/>
            </b-form-group>
          </td>
          <td style="min-width: 16rem;">
            <vue-ctk-date-time-picker
              auto-close
              only-date
              no-label
              label=""
              :min-date="rvpPlanConfigForm.start_date"
              format="YYYY-MM-DD"
              formatted="ll"
              v-model="rvpPlanConfigForm.end_date"/>
          </td>
          <td>
            <b-button
              data-testid="btn-add-rvp-plan-config"
              variant="secondary"
              :disabled="isLoading"
              @click="onCreate">
              {{ translations.plan_configs.btn_add }}
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { parseResponseError } from '@/http/parsers/parse_response';
import errorHandler from '@/mixins/errorHandler';
import translations from '@/translations';
import service from '@/services/commission';
import { formatDate } from '@/helpers/finance';
import {
  planNames,
  configsKeysMappedByPlan,
  configsKeys,
} from '@/constants/commissions/rvps_plan_configs';

const formDefault = {
  plan_id: null,
  key: '',
  value: '',
  start_date: '',
  end_date: null,
  status: 'new',
};

export default {
  name: 'ListAndAddRVPsPlanConfigs',
  mixins: [ errorHandler ],
  props: {
    rvpUuid: {
      type: String,
      required: true,
    },
    currentConfigs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    rules() {
      return {
        rvpPlanConfigForm: {
          plan_id: { required },
          key: { required },
          value: { required },
          start_date: { required },
        },
      };
    },
    planOptions() {
      return planNames
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(plan => ({ text: plan.name, value: plan.id }));
    },
    keysOptions() {
      return configsKeysMappedByPlan[this.rvpPlanConfigForm.plan_id] || [];
    },
  },
  watch: {
    currentConfigs: {
      handler: 'formatRvpPlanConfigs',
      immediate: true,
    },
  },
  data() {
    return {
      translations: translations.commissions.rvps,
      configsKeys,
      configsFormated: [],
      newConfigs: [],
      isLoading: false,
      rvpPlanConfigForm: cloneDeep(formDefault),
      shouldValidateForm: false,
      statusBadgeVariant: {
        new: 'secondary',
        active: 'success',
        inactive: 'danger',
      },
    };
  },
  validations() {
    return this.rules;
  },
  methods: {
    formatDate,
    formatRvpPlanConfigs(plans) {
      const mapped = plans.flatMap(plan => plan.rvp_plan_configs.map(cfg => ({
        id: cfg.id,
        plan_id: plan.plan_id,
        key: cfg.key,
        value: cfg.value,
        start_date: cfg.start_date,
        end_date: cfg.end_date,
        status: cfg.status || 'new',
      })));

      mapped.forEach(cfg => {
        const index = this.configsFormated.findIndex(c => c.id === cfg.id);
        if (index !== -1) this.configsFormated.splice(index, 1, cfg);
        else this.configsFormated.push(cfg);
      });

      // order by plan_id and start_date, except when plan_id is 7, plan_id 7 always should be the first one
      this.configsFormated = this.configsFormated.sort((a, b) => {
        if (a.plan_id === 7 && b.plan_id !== 7) return -1;
        if (a.plan_id !== 7 && b.plan_id === 7) return 1;
        if (a.plan_id === b.plan_id) return moment(a.start_date).diff(b.start_date);
        return a.plan_id - b.plan_id;
      });
    },
    isValidState(fieldName) {
      if (!this.shouldValidateForm) return true;
      return this.isValid(fieldName);
    },
    getPlanNameById(planId) {
      return planNames.find(plan => plan.id === planId).name;
    },
    handleChangePlanId() {
      this.rvpPlanConfigForm.key = '';
    },
    formatInputValue(value) {
      return value?.replace(/[^0-9.]/g, '');
    },
    async onCreate() {
      this.shouldValidateForm = true;

      if (this.$v.$invalid) {
        return;
      }

      try {
        this.isLoading = true;
        const config = {
          plan_id: this.rvpPlanConfigForm.plan_id,
          rvp_plan_config: {
            key: this.rvpPlanConfigForm.key,
            value: this.rvpPlanConfigForm.value,
            start_date: this.rvpPlanConfigForm.start_date,
            end_date: this.rvpPlanConfigForm.end_date,
          },
        };

        const configsOfPlanId = await service.createRVPPlan(this.rvpUuid, config);
        this.formatRvpPlanConfigs([ configsOfPlanId ]);
        this.rvpPlanConfigForm = cloneDeep(formDefault);
        this.$noty.success(this.translations.success.create_plan);
      } catch (err) {
        this.$noty.error(parseResponseError(err));
      } finally {
        this.shouldValidateForm = false;
        this.isLoading = false;
      }
    },
  },
};
</script>
