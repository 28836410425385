export const planNames = [
  { id: 1, name: '2022 ASO' },
  { id: 2, name: '2022 RVP' },
  { id: 3, name: '2023 ASO' },
  { id: 4, name: '2023 RVP' },
  { id: 5, name: '2024 ASO' },
  { id: 6, name: '2024 RVP' },
  { id: 7, name: '2021 ASO' },
];

export const configsKeys = {
  rvp_revenue_pct: 'RVP Revenue %',
  manager_revenue_pct: 'Manager Revenue %',
  rvp_revenue_after_10M_pct: 'RVP Revenue % After 10M',
  rvp_revenue_after_quota_pct: 'RVP Revenue % After Quota',
  rvp_quota: 'RVP Quota',
};

const configsKeysMap = {
  rvp_revenue_pct: { value: 'rvp_revenue_pct', text: 'RVP Revenue %' },
  manager_revenue_pct: { value: 'manager_revenue_pct', text: 'Manager Revenue %' },
  rvp_revenue_after_10M_pct: { value: 'rvp_revenue_after_10M_pct', text: 'RVP Revenue % After 10M' },
  rvp_revenue_after_quota_pct: { value: 'rvp_revenue_after_quota_pct', text: 'RVP Revenue % After Quota' },
  rvp_quota: { value: 'rvp_quota', text: 'RVP Quota' },
};

export const configsKeysMappedByPlan = {
  1: [ // 2022 ASO
    configsKeysMap.rvp_revenue_pct,
    configsKeysMap.manager_revenue_pct,
  ],
  2: [ // 2022 RVP
    configsKeysMap.rvp_revenue_pct,
    configsKeysMap.manager_revenue_pct,
  ],
  3: [ // 2023 ASO
    configsKeysMap.rvp_revenue_pct,
    configsKeysMap.rvp_revenue_after_10M_pct,
  ],
  4: [ // 2023 RVP
    configsKeysMap.rvp_revenue_pct,
    configsKeysMap.rvp_revenue_after_quota_pct,
    configsKeysMap.rvp_quota,
  ],
  5: [ // 2024 ASO
    configsKeysMap.rvp_revenue_pct,
    configsKeysMap.rvp_revenue_after_10M_pct,
  ],
  6: [ // 2024 RVP
    configsKeysMap.rvp_revenue_pct,
  ],
  7: [ // 2021 ASO
    configsKeysMap.rvp_revenue_pct,
    configsKeysMap.manager_revenue_pct,
  ],
};
